import xpSolvService from "../axios/axios";

class TestService {

    getClasses() {
        return Promise.resolve(xpSolvService.get('ordertest/register/classes'));
    }

    getSchools() {
        return Promise.resolve(xpSolvService.get('ordertest/register/schools'));
    }

    initializeTest(data) {
        return Promise.resolve(xpSolvService.get(`plantest/initializetest/${data}`));
    }

    orderTest(data) {
        return Promise.resolve(xpSolvService.post('plantest/ordertest/preparequespaper', data));
    }

    preparingOrderTest(data) {
        return Promise.resolve(xpSolvService.get(`pdf/generatequespaper?id=${data}&format=pdf&fallback=0`));
    }

    downloadTest(data) {
        return Promise.resolve(xpSolvService.get(`pdf/getquespaperpdf/${data}`));
    }

    viewTestQuestion(data) {
        return Promise.resolve(xpSolvService.get(`pdf/generatequespaper?id=${data}&format=html&fallback=0`, {responseType:'text/html'}));
    }

    sendEmailTest(data) {
        return Promise.resolve(xpSolvService.post('sendtest', data));
    }

    reviewAnswer(data) {
        return Promise.resolve(xpSolvService.get(`reviewanswers/${data}`));
    }

    updateReviewAnswerStatus(data) {
        return Promise.resolve(xpSolvService.put('reviewanswers/updateAnswerStatuses', data));
    }
    
    initializeTestByStudentIdAndClassId(studentId,classId) {
        return Promise.resolve(xpSolvService.get(`plantest/initializetest/?studentId=${studentId}&classId=${classId}`));
    }

    teacherClasses(studentId) {
        return Promise.resolve(xpSolvService.get(`plantest/teacherclasses/${studentId}`));
    }

    publishToStudent(data) {
        return Promise.resolve(xpSolvService.post('publishtest/publish', data));
    }
}

export default TestService;