import { faker } from '@faker-js/faker';
// @mui
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography,Card ,CardContent ,CardActions,Button, Box   } from '@mui/material';
// components
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Page from '../components/Page';
import styles from "../custom/Custom.module.css";
import { dashApi,utilApi } from 'src/service';

import Iconify from '../components/Iconify';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [studentList, setStudentList] = useState([]);
 
  useEffect(()=>{
  getStudent();
},[])

const openPage=(url,statedata)=>{
  const NavigateData = { replace: true };
  if(statedata){
    NavigateData.state= statedata;
  }
  navigate(url, NavigateData);
 
}

  const getStudent = () => {
    try {
      dashApi.studentList().then(
        (resp) => {
          //console.log("resp",resp.data.response.studentInfo);
          setStudentList(resp.data.response.studentInfo);
        },
        (error) => {
            const errorMsg = utilApi.getErrorMsg(error.response.data);
            console.log(errorMsg);      
          }
      );
    }
    catch (error) {
      console.log("Catch",error)
    }
  }



  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }} className={styles.floattetx}>
          Hi, Welcome back
        </Typography>
        <Box  className={styles.floatright} >
          <Button  variant="contained" onClick={()=>openPage('/dashboard/student','')} className={styles.btnmain} startIcon={<Iconify icon="eva:plus-fill"  />}>
            Add Student
          </Button>
        </Box>
      <Grid container spacing={3}  className={styles.mto00}>
       

      {studentList.map((student, index) => (
        
          <Grid item xs={12} sm={6} md={3}> 
            <Card  className={styles.overflow}>
            <img src="/static/illustrations/shape-avatar (1).png" alt="login" className={styles.avtar2}/>
            <img src="/static/illustrations/M.png" alt="login" className={styles.avtar}/>
               
              <CardContent className={styles.padd000} >
           
                <Typography varient="h3" color="text.secondary" className={styles.stname}>
                 {student.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" className={styles.stclass}>
                {student.schoolname}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" className={styles.stclass}>
                {student.classname}
                </Typography>
                <Box className={styles.contact}> 
              
                    <Typography variant="p" component="p" className={styles.maindot3}>
                        <span className={styles.floatleft}>
                          <img src="/static/illustrations/phone-call (3).png" alt="login" className={styles.imgwidh2}/>
                        </span>
                        {student.studentphone ?student.studentphone : "-"}
                      </Typography>
                
                      <Typography variant="p" component="p" className={styles.maindot3}>
                        <span className={styles.floatleft}>
                          <img src="/static/illustrations/envelope (2).png" alt="login" className={styles.imgwidh2}/>
                        </span>
                        {student.studentemail? student.studentemail :"-"}
                      </Typography>
                    
                
                </Box>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={()=>openPage("/dashboard/create-test",student)}  className={styles.btnmain}>Plan New Test</Button>
                    </CardActions>
                  </Card>
                </Grid>
)) }
            
          
        
        </Grid>
    
      </Container>
    </Page>
  );
}
