import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton,Button, InputAdornment, FormControlLabel,Box,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import styles from "../../../custom/Custom.module.css";
import { AuthApi,utilApi } from '../../../service/index';



// ----------------------------------------------------------------------
const useStyles = makeStyles({
  imageooo:{
    background:'#000',
  }
});

export default function LoginForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoder, setShowLoader] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState("");
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState("");
  const [count, setCount] = useState(30);
  const [timer, setTimer] = useState(30);
  const [intervalId, setIntervalId] = useState(0);


  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Mobile is required'),
    otp: Yup.string().required('OTP is required')
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      otp: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      signIn(data);
    },
  });

  
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(29);
      
    }

    const newIntervalId = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText("");
    if(!formik.values.phone)
    {
        setshowValidation(true);
        setShowValidationText("Mobile not found");
        return false;
    }

    const data = {
      "phone": formik.values.phone
    };
    setShowLoader(true);
    AuthApi.generateOTP(data).then(resp => {
      setShowLoader(false);
      if(resp.data.response.data)
      {
        formik.setFieldValue('otp', resp.data.response.data.otp)
        setCount(29);
        handleClick();
      }
      
     
      // setShowOTP(resp.data.data)      
    }) 
    .catch(function (error) {
        formik.setSubmitting(false);
        setshowValidation(true);
        const errorMsg = utilApi.getErrorMsg(error.response.data);
        setShowValidationText(errorMsg);
      })
  }

  

  const signIn = (data) => {
    
    setShowLoader(true);
    setshowPassValidation(false);
    setShowPassValidationText("");
    try {
      AuthApi.signIn(data).then(
        (resp) => {
          formik.resetForm();
          console.log("resp",resp.data.response.token);
          utilApi.encrypt("userInfo",resp.data.response.userInfo);
          utilApi.encrypt("token",resp.data.response.token);
          utilApi.encrypt("usertype",resp.data.response.userInfo.usertype);
          navigate('/dashboard/app', { replace: true });
          
        },
        (error) => {
            formik.setSubmitting(false);
            setshowPassValidation(true);
            const errorMsg = utilApi.getErrorMsg(error.response.data);
            setShowPassValidationText(errorMsg);
            
          }
      );
    }
    catch (error) {
      setShowLoader(false);
      console.log("Catch",error)
    }
  }

  return (
    <div className='imageooo' style={{position:'relative'}}>
      <div style={{position: "absolute", background: "#fff", width: "384px", height: "369px", top: "-134px", borderRadius: "32px", left: "-28px", zIndex: "-1", transform: "rotate(98deg)",opacity: "0.5", boxShadow: "1px 1px 40px #d1d1d1"}}>.</div>
     
    <FormikProvider value={formik}  >
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
          InputProps={{
            endAdornment:(
              <InputAdornment position="end">
                {count >=timer &&
                <Button  className={classes.getotpbg} onClick={generateOTP} size="large" variant="text">Get OTP</Button>
             
                }
                <>
                {count < 0 &&
                <Button  className={classes.getotpbg} onClick={generateOTP} size="large" variant="text">Resend OTP</Button>
             
                }
                </>
                <>
                {count >= 0 && count < timer &&
                <Button  className={classes.getotpbg}  size="large" variant="text">{count} Sec.</Button>
             
                }
                </>
                 </InputAdornment>
            )
          }}
            fullWidth
            autoComplete="mobile"
            type="text"
            label="Mobile"
            {...getFieldProps('phone')}
            error={(Boolean(touched.phone && errors.phone)) || showValidation}
            helperText={(touched.phone && errors.phone) || showValidationText }
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="OTP"
            {...getFieldProps('otp')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={(Boolean(touched.otp && errors.otp)) || showPassValidation}
            helperText={(touched.otp && errors.otp)  || showPassValidationText}
          />
        </Stack>

        

        <LoadingButton fullWidth size="large"
         type="submit" variant="contained"
          loading={isSubmitting} style={{marginTop:'33px'}}
            className={styles.btnbtnbutton} >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
    </div>
  );
}
