import { useState } from 'react';
// material
import { Container, Stack, Typography,Box ,FormControl, FormLabel, RadioGroup,FormControlLabel ,Radio,Grid,InputLabel,Select,MenuItem,Button } from '@mui/material';
// components
import Page from '../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import styles from "../custom/Custom.module.css";

// ----------------------------------------------------------------------

export default function CreateTest() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <Page title="Dashboard: Products">
      <Container>
      
        <Box className={styles.cardsearch2}>
        <Typography variant="h4" sx={{ mb: 5 }} className={styles.mbuu}>
          Plan Test
        </Typography>
        <Grid container spacing={3} className={styles.cardtable} >
          <Grid item xs={12} sm={12} md={6}> 
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Test Format</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
          
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}> 
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Level</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
          
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}> 
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Subject</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Age"
          
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          </Grid>
       
          <Grid item xs={12} sm={12} md={12}> 
          <Button variant="contained" className={styles.btnmain2}>Genrate Test</Button>

          </Grid>
        </Grid>
        </Box>
      

      
      </Container>
    </Page>
  );
}
