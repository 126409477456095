import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { StudentForm } from '../sections/auth/register';

// ----------------------------------------------------------------------




// ----------------------------------------------------------------------

export default function Student() {
  const smUp = useResponsive('up', 'sm');



  return (
    <Page title="Register">
            <StudentForm />
        
    </Page>
  );
}
