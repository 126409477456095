import AuthService from "./auth/auth";
import TestService from "./test/test";
import UserService from "./user/user";
import SharedService from "./shared/shared";
import UtilitiesService from "./utilities/utilities";
import HistoryTestService from "./historyTest/historyTest";
import DashboardService from "./dashboard/DashboardService";

const AuthApi = new AuthService();
const TestApi = new TestService();
const userApi = new UserService();
const sharedApi = new SharedService();
const historyApi = new HistoryTestService();
const utilApi = new UtilitiesService();
const dashApi = new DashboardService();


export { AuthApi, TestApi, userApi, historyApi, sharedApi, utilApi, dashApi }