import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Box, FormControl, InputLabel,Select,MenuItem,Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import styles from "../../../custom/Custom.module.css";
import { AuthApi,utilApi } from '../../../service/index';
// ----------------------------------------------------------------------

export default function StudentForm() {
  const navigate = useNavigate();
  const [classInfo, setClassInfo] = useState([]);
  const [genderInfo, setGenderInfo] = useState([]);
  const [schoolInfo, setSchoolInfo] = useState([]);
  useEffect(()=>{
    studentInit()
  },[])

  const RegisterStudentSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is required'),
    classid: Yup.number().max(50, 'Too Long!').required('Class is required'),
    gender: Yup.string().required('Gender is required'),
    studentemail: Yup.string().email('Email must be a valid email address'),
    studentphone: Yup.number("Must be number"),
    schoolid: Yup.number("Must be number").required('School is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      classid: '',
      gender: '',
      studentemail: '',
      studentphone: '',
      schoolid: '',
    },
    validationSchema: RegisterStudentSchema,
    onSubmit: (data) => {
      registerStudent(data);
    //  navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const registerStudent = (data) => {
    
    try {
      AuthApi.registerStudent(data).then(
        (resp) => {
          formik.resetForm();
          navigate('/dashboard/app', { replace: true });
          
        },
        (error) => {
            formik.setSubmitting(false);
            const errorMsg = utilApi.getErrorMsg(error.response.data);            
          }
      );
    }
    catch (error) {
      console.log("Catch",error)
    }
  }

  const studentInit = () => {
    
    try {
      AuthApi.studentInit().then(
        (resp) => {
          console.log(resp.data.response.studentInit);
          setClassInfo(resp?.data?.response?.studentInit?.classInfo);
          setGenderInfo(resp?.data?.response?.studentInit?.genderInfo);
          setSchoolInfo(resp?.data?.response?.studentInit?.schoolInfo);
          
        },
        (error) => {
            const errorMsg = utilApi.getErrorMsg(error.response.data);            
          }
      );
    }
    catch (error) {
      console.log("Catch",error)
    }
  }

  return (
    <Box className={styles.bgmain}>
      <img src="/static/illustrations/tool1.png" alt="login" className={styles.imagestore6} />
      <img src="/static/illustrations/tool2.png" alt="login" className={styles.imagestore7}/>
      <img src="/static/illustrations/tool3.png" alt="login" className={styles.imagestore8}/>
      <img src="/static/illustrations/tool4.png" alt="login" className={styles.imagestore9}/>
      <img src="/static/illustrations/tool5.png" alt="login" className={styles.imagestore10}/>
      <Typography variant="h4" sx={{ mb: 5 }} className={styles.headingline}>
       Student Form
        </Typography>
    <FormikProvider value={formik} >
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

<FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">School</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="School"
              {...getFieldProps('schoolid')}
              error={Boolean(touched.schoolid && errors.schoolid)}
              helperText={touched.schoolid && errors.schoolid}
            >
              <MenuItem value="">Select</MenuItem>
              {schoolInfo?.map((school, index) => (
                  <MenuItem value={school.schoolid}>{school.schoolname}</MenuItem>
              ))}
              
              
            </Select>
            {Boolean(touched.schoolid && errors.schoolid) &&
            <Typography variant="p" className={styles.errormsg}>{touched.schoolid && errors.schoolid}</Typography>}
          </FormControl>
          </Stack>
        
          <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Class"
              {...getFieldProps('classid')}
              error={Boolean(touched.classid && errors.classid)}
              helperText={touched.classid && errors.classid}
            >
              <MenuItem value="">Select</MenuItem>
              {classInfo?.map((classdata, index) => (
                  <MenuItem value={classdata.studentclassid}>{classdata.classname}</MenuItem>
              ))}
              
              
            </Select>
            {Boolean(touched.classid && errors.classid) &&
            <Typography variant="p" className={styles.errormsg}>{touched.classid && errors.classid}</Typography>}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Gender"
              {...getFieldProps('gender')}
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
            >
                <MenuItem value="">Select</MenuItem>
             {genderInfo?.map((gender, index) => (
                  <MenuItem value={gender.gendercode}>{gender.gendername}</MenuItem>
              ))}
            </Select>
            {Boolean(touched.gender && errors.gender) &&
            <Typography variant="p" className={styles.errormsg}>{touched.gender && errors.gender}</Typography>}

          </FormControl>
          </Stack>
          
          </Stack>
          <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
              fullWidth
              label="Mobile No."
              {...getFieldProps('studentphone')}
              error={Boolean(touched.studentphone && errors.studentphone)}
              helperText={touched.studentphone && errors.studentphone}
          />
              <TextField
              fullWidth
              label="Email ID"
              {...getFieldProps('studentemail')}
              error={Boolean(touched.studentemail && errors.studentemail)}
              helperText={touched.studentemail && errors.studentemail}
          />
            </Stack>
            </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}  className={styles.btnclear}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
    </Box>
  );
}
