import xpSolvService from "../axios/axios";

class HistoryTestService {

    historyTest(params) {
        const apiURL = 'testaudit/gettesthistory?';
        return Promise.resolve(xpSolvService.get(apiURL+params));
    }

    getStudentSubject() {
        return Promise.resolve(xpSolvService.get(`report/getstudentsubject`));
    }

    generateReport(data) {
        return Promise.resolve(xpSolvService.post('report/generatereport?fallback=0', data));
    }
}

export default HistoryTestService;