import axios from "axios";
import { utilApi } from "../../service/index";

// axios instance for making requests 
const xpSolvService = axios.create({
    baseURL: window.location.origin== 'https://schoolapp.smartmanager.in'? "https://api.smartmanager.in/api/":"http://local.api.smartmanager.in/api/",
    headers: {
        "Content-type": "application/json",
        "Accept":"application/json",
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Allow-Methods":"GET, POST, PUT, PATCH, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":"Content-Type, Authorization,Accept",
    }
});


// request interceptor for adding token
xpSolvService.interceptors.request.use((config) => {
    // add token to request headers
    config.headers.Authorization = `Bearer ${utilApi.decrypt('token')}`;
    return config;
});

export default xpSolvService;