import xpSolvService from "../axios/axios";

class AuthService {

    generateOTP(data) {
        return Promise.resolve(xpSolvService.post('sendOtp', data));
    }

    signIn(data) {
        return Promise.resolve(xpSolvService.post('signin', data));
    }

    registerStudent(data) {
        return Promise.resolve(xpSolvService.post('student/store', data));
    }
    
    signout() {
        return Promise.resolve(xpSolvService.post('signout', ''));
    }

    studentInit() {
        return Promise.resolve(xpSolvService.get('studentInit', ''));
    }
}

export default AuthService;