import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import styles from "../custom/Custom.module.css";


// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  h4heaidng:{
    background:'#000',
  },
}));


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    
    <Page  className={styles.design} title="Login" style={{background:'url(/static/illustrations/bgdot.png)',height:'100vh',width: "100%", backgroundSize: "contain"}}>
      <Box className={styles.maindot}> 
      <img src="/static/illustrations/dummy.png" alt="login" className={styles.imageshape}/>
      <Typography variant="h6" component="h2" className={styles.maindot2}>
          <span className={styles.floatleft}>
            <img src="/static/illustrations/phone-call (3).png" alt="login" className={styles.imgwidh}/>
          </span>
          +91 8595859254
        </Typography>
        <Typography variant="h6" component="h2" className={styles.maindot2}>
          <span className={styles.floatleft}>
            <img src="/static/illustrations/phone-call (3).png" alt="login" className={styles.imgwidh}/>
          </span>
          +91 8595859254
        </Typography>
      </Box>
      <Container style={{position:'relative',}}>
      <img src="/static/illustrations/tool1.png" alt="login" className='imageooo' style={{width: '160px',objectFit:'cover',position: "absolute", right: "420px", top: "80px", zIndex: "-1"}}/>
      <img src="/static/illustrations/tool2.png" alt="login" className='imageooo' style={{width: '160px',objectFit:'cover',position: "absolute", right: "470px", top: "439px", zIndex: "-1"}}/>
      <img src="/static/illustrations/tool3.png" alt="login" className='imageooo' style={{width: '22px',objectFit:'cover',position: "absolute", right: "98px", top: "460px", zIndex: "-1", transform: "rotate(135deg)",opacity:0.7,}}/>
      <img src="/static/illustrations/tool4.png" alt="login" className='imageooo' style={{width: '160px',objectFit:'cover',position: "absolute", right: "29px", top: "311px", zIndex: "-1"}}/>
      <img src="/static/illustrations/tool5.png" alt="login" className='imageooo' style={{width: '160px',objectFit:'cover',position: "absolute", right: "70px", top: "58px", zIndex: "-1",}}/>
      
      
      
      <RootStyle>   
            <img src="/static/illustrations/icon1.png" alt="login" className={styles.mainimg}/>
      
        
        <Container maxWidth="sm"  >
          <div style={{width: "386px", background: "#fff", padding: "30px", boxShadow: "1px 1px 30px #dedede", borderRadius: "32px", marginTop: "183px"}}>
            <Typography variant="h4" gutterBottom style={{fontWegiht:'700'}}>
              LOGIN
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

           

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )}
          </div>
         
        </Container>
      </RootStyle>
      </Container>
      <img src="/static/illustrations/login2.png" alt="login" className='imageooo' style={{width: '100%',objectFit:'cover',position: "absolute", bottom: "0px",    filter: 'drop-shadow(0px 0px 33px #eee)'}}/>
    </Page>
  );
}
