var CryptoJS = require("crypto-js");
import {errorConstant} from './errorConstant'
const secretKey = '2iR>T5ELO~BQs3Q/W(`-!@<`gKR&B)vCCr_TJ??Y!&8HthIC>5N/:U#C9w.Bu~!1234567890';
class UtilitiesService {
    
    
    base64ToPDF(pdf, fileName) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    encrypt(key,value)
    {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
        localStorage.setItem(key,ciphertext);
    }

    decrypt(key)
    {
        let originalText = "";
        if(localStorage.getItem(key))
        {
            const ciphertext = localStorage.getItem(key);
            const bytes  = CryptoJS.AES.decrypt(ciphertext, secretKey);
            originalText = bytes.toString(CryptoJS.enc.Utf8);
            originalText =  JSON.parse(originalText);
        }
        
        return originalText;
    }

    checkLogin()
    {
        if(localStorage.getItem("token") && localStorage.getItem("usertype"))
        {
            return true;
        }
        return false;
    }

    logout(){
        localStorage.removeItem("token"); 
        localStorage.removeItem("usertype");
        localStorage.removeItem("userInfo");
    }

    getErrorMsg(data){
        console.log(data);
        let error = "";
        if (data.isValidator) {
            error = data.response.error[0];
          }
          else{
            error =data.response.error;
            error = this.changeErrorMsg(error);
          }
        return error;
    }

    changeErrorMsg(key)
    {
        let msg = key;
        if(errorConstant[key])
        {
            msg = errorConstant[key];
        }
        return msg;
        
    }
}

export default UtilitiesService;