import xpSolvService from "../axios/axios";

class DashboardService {

    studentList() {
        return Promise.resolve(xpSolvService.get('student'));
    }

    
}

export default DashboardService;